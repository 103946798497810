import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "../api/authService";
import { TextField, Button, Typography, Alert, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  // const location = useLocation();

  // Extract token from URL query parameter
  // const token = new URLSearchParams(location.search).get("token");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Clear previous messages
    setMessage("");

    if (newPassword !== newPasswordAgain) {
      setMessage("Passwords do not match. Please try again.");
      setIsSuccess(false);
      return;
    }

    try {
      const response = await authService.resetPassword(
        newPassword,
        newPasswordAgain
      );
      if (response.message === "success") {
        setMessage("Password updated successfully.");
        setIsSuccess(true);
        setTimeout(() => navigate("/"), 3000); // Redirect after 3 seconds
      } else {
        setMessage("Failed to update password. Please try again.");
        setIsSuccess(false);
      }
    } catch (error) {
      setMessage("Error:", error.message);
      setIsSuccess(false);
    }
  };

  return (
    <div className="w-full mx-auto">
      <div className="flex w-full items-center justify-between ">
        <h2 className="text-4xl text-gray-700 font-semibold mb-8">
          Reset Password
        </h2>

        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={() => navigate("/settings")}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
      </div>

      {message && (
        <Alert severity={isSuccess ? "success" : "error"} sx={{ mb: 2 }}>
          {message}
        </Alert>
      )}
      <form onSubmit={handleSubmit} className="max-w-[400px]">
        <TextField
          label="New Password"
          type="password"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
          size="small"
          margin="dense"
        />
        <TextField
          label="Re-enter New Password"
          type="password"
          fullWidth
          value={newPasswordAgain}
          onChange={(e) => setNewPasswordAgain(e.target.value)}
          required
          size="small"
          margin="dense"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          // fullWidth
          sx={{ textTransform: "none", marginTop: "16px" }}
        >
          Update Password
        </Button>
      </form>
    </div>
  );
}

export default ResetPassword;
