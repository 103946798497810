import React from "react";

const Integrations = () => {
  return (
    <div className="w-full mx-auto">
      <h2 className="text-4xl text-gray-700 font-semibold mb-8">
        Integrations
      </h2>
      <div className="text-gray-500">
        No integrations available at the moment. Stay tuned for upcoming
        features and enhancements.
      </div>
    </div>
  );
};

export default Integrations;
