import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItemButton,
  IconButton,
  Badge,
  Typography,
} from "@mui/material";
import {
  Dashboard as HomeIcon,
  Layers as IntegrationsIcon,
  Help as HelpIcon,
  Notifications as NotificationsIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(true);

  // Retrieve email from localStorage
  const userEmail = localStorage.getItem("userEmail") || "User";

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("userEmail");
    navigate("/");
  };

  const menuItems = [
    {
      text: "Dashboard",
      icon: <HomeIcon />,
      path: "/dashboard",
      disabled: false,
    },
    {
      text: "Integrations",
      icon: <IntegrationsIcon />,
      path: "/integrations",
      disabled: false,
    },
  ];

  const fixedItems = [
    {
      text: "Help / Support",
      icon: <HelpIcon />,
      path: "/support",
      disabled: false,
    },
    {
      text: "Notifications",
      icon: (
        <Badge badgeContent={0} color="primary" showZero>
          <NotificationsIcon />
        </Badge>
      ),
      path: "/notifications",
      disabled: false,
    },
    {
      text: "User Settings",
      icon: <SettingsIcon />,
      path: "/settings",
      disabled: false,
    },
    {
      text: "Logout",
      icon: <LogoutIcon />,
      action: handleLogout,
      disabled: false,
    },
  ];

  const handleNavigation = (path) => {
    if (path) navigate(path);
  };

  const drawerWidth = open ? 220 : 65;

  return (
    <div className="min-h-screen flex flex-col">
      {/* Navbar */}
      <AppBar
        position="sticky"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar className="flex justify-between">
          <div className="flex items-center">
            <IconButton
              color="inherit"
              onClick={() => setOpen(!open)}
              edge="start"
              sx={{ marginRight: 2 }}
            >
              {open ? (
                <MenuOpenIcon titleAccess="Collapse menu" />
              ) : (
                <MenuIcon titleAccess="Open menu" />
              )}
            </IconButton>
            <h2 className="text-2xl font-semibold">b2boptimize.ai</h2>
          </div>
          <div className="flex items-center gap-4">
            <Typography variant="body1" className="font-medium">
              Welcome {userEmail}!
            </Typography>
          </div>
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <div className="flex flex-1">
        {/* Sidebar */}
        <Drawer
          variant="permanent"
          anchor="left"
          sx={{
            width: drawerWidth,
            transition: "width 0.2s ease-in-out",
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              transition: "width 0.2s ease-in-out",
              boxSizing: "border-box",
              paddingY: "0.5rem",
              overflowX: "hidden",
            },
          }}
        >
          <Toolbar />
          <List>
            {menuItems.map((item, index) => (
              <ListItemButton
                title={item.text}
                key={index}
                onClick={() => handleNavigation(item.path)}
                disabled={item.disabled}
                selected={location.pathname === item.path}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  "&.Mui-selected": {
                    backgroundColor: "white",
                  },
                  "&.Mui-selected .MuiListItemIcon-root": {
                    color: "primary.main",
                  },
                  "&.Mui-selected .MuiListItemText-primary": {
                    color: "primary.main",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                {open && <ListItemText primary={item.text} />}
              </ListItemButton>
            ))}
          </List>
          <Divider />
          <List sx={{ marginTop: "auto" }}>
            {fixedItems.map((item, index) => (
              <ListItemButton
                title={item.text}
                key={index}
                onClick={() =>
                  item.action ? item.action() : handleNavigation(item.path)
                }
                disabled={item.disabled}
                selected={location.pathname === item.path}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  "&.Mui-selected": {
                    backgroundColor: "white",
                  },
                  "&.Mui-selected .MuiListItemIcon-root": {
                    color: "primary.main",
                  },
                  "&.Mui-selected .MuiListItemText-primary": {
                    color: "primary.main",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                {open && <ListItemText primary={item.text} />}
              </ListItemButton>
            ))}
          </List>
        </Drawer>

        {/* Children */}
        <main className="flex-1 py-10 px-14 box-border bg-white">
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
