import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const UserSettings = () => {
  const navigate = useNavigate();
  // Retrieve email from localStorage
  const userEmail = localStorage.getItem("userEmail") || "User";

  return (
    <div className="w-full mx-auto">
      <h2 className="text-4xl text-gray-700 font-semibold mb-8">
        User Settings
      </h2>

      <div className="max-w-fit grid grid-cols-2 gap-x-10 gap-y-4 mb-4">
        <span className="font-semibold">Email</span>
        <span>{userEmail}</span>
        <span className="font-semibold">Email Domain</span>
        <span>{userEmail !== "User" && userEmail.split("@")[1]}</span>
      </div>

      <Button
        variant="outlined"
        sx={{ textTransform: "none" }}
        onClick={() => navigate(`/reset_password`)}
      >
        Reset Password
      </Button>
    </div>
  );
};

export default UserSettings;
