import apiClient from "./apiClient";

export const authService = {
  /**
   * Logs in a user with the provided email and password.
   * @param {string} email - User's email.
   * @param {string} password - User's password.
   * @returns {Promise<Object>} - The response data from the API.
   */
  login: async (email, password) => {
    try {
      const response = await apiClient.post(`/api/login`, {
        email,
        password,
      });
      return response.data;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to log in. Please try again."
      );
    }
  },

  /**
   * Sends a forgot password request for the provided email.
   * @param {string} email - User's email.
   * @returns {Promise<Object>} - The response data from the API.
   */
  forgotPassword: async (email) => {
    try {
      const response = await apiClient.post(`/api/forgot_password`, {
        email,
      });
      return response.data;
    } catch (error) {
      throw new Error(
        error.response?.data?.message ||
          "Failed to send forgot password request. Please try again."
      );
    }
  },

  /**
   * Registers a new user with the provided signup details.
   * @param {Object} userData - The signup details.
   * @param {string} userData.user_name - User's name.
   * @param {string} userData.email - User's email.
   * @param {string} userData.password - User's password.
   * @returns {Promise<Object>} - The response data from the API.
   */
  signup: async (userData) => {
    try {
      const response = await apiClient.post(`/api/signup`, userData);
      return response.data;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to sign up. Please try again."
      );
    }
  },

  /**
   * Resets the password for the user with the provided token and new password.
   * @param {string} token - Reset password token.
   * @param {string} newPassword - New password.
   * @param {string} newPasswordAgain - New password again.
   * @returns {Promise<Object>} - The response data from the API.
   */
  resetPassword: async (newPassword, newPasswordAgain) => {
    try {
      const response = await apiClient.post(`/api/reset_password`, {
        new_password: newPassword,
        new_password_again: newPasswordAgain,
      });
      return response.data;
    } catch (error) {
      throw new Error(
        error.response?.data?.message ||
          "Failed to reset password. Please try again."
      );
    }
  },
};
